import {
  ADMIN_CONFIG_ROUTE,
  CATEGORY_ROUTE,
  CATEGORY_CREATE_ROUTE,
  CATEGORY_DETAIL_ROUTE,
  CHANGE_CONTROLS_DETAIL_ROUTE,
  CYCLES_CHANGE_CONTROLS_ROUTE,
  CYCLES_CREATE_ROUTE,
  CYCLES_DETAIL_ROUTE,
  CYCLES_SPACES_ROUTE,
  CYCLES_MASTER_PSA_ROUTE,
  CYCLES_NOMINATIONS_ROUTE,
  CYCLES_ROUTE,
  CYCLES_SHIPPER_COMPLIANCE_ROUTE,
  CYCLES_SSPSA_COMMENTS_ROUTE,
  CYCLES_STORE_PSA_ROUTE,
  CYCLES_STORE_PSA_SEARCH_ROUTE,
  IMPORTS_ROUTE,
  NOMINATIONS_DETAIL_ROUTE,
  NOMINATIONS_ROUTE,
  PRODUCT_ROUTE,
  SPACES_DETAIL_ROUTE,
  SPACES_ROUTE,
  SPACE_ASSIGNMENTS_CHANGE_CONTROL_ROUTE,
  SPACE_ASSIGNMENTS_CLUSTER_ROUTE,
  SPACE_ASSIGNMENTS_POS_ROUTE,
  SPACE_ASSIGNMENTS_CREATE_ROUTE,
  SPACE_ASSIGNMENTS_DETAIL_ROUTE,
  SPACE_PLANS_APPROVALS_ROUTE,
  SPACE_PLANS_CREATE_ROUTE,
  SPACE_PLANS_CYCLES_ROUTE,
  SPACE_PLANS_DETAIL_ROUTE,
  SPACE_PLANS_PLANNER_ROUTE,
  SPACE_PLANS_ROUTE,
  SPACE_PLANS_TIMELINE_ROUTE,
  SPACE_TYPES_CREATE_ROUTE,
  SPACE_TYPES_DETAIL_ROUTE,
  SPACE_TYPES_ROUTE,
  SPACE_GROUPS_CREATE_ROUTE,
  SPACE_GROUPS_DETAIL_ROUTE,
  SPACE_GROUPS_ROUTE,
  SSPSA_ROUTE,
  USERS_DETAIL_ROUTE,
  GROUPS_CREATE_ROUTE,
  GROUPS_ROUTE,
  GROUPS_DETAIL_ROUTE,
} from './routes'

import {
  READ_CATEGORIES,
  CREATE_CATEGORIES,
  UPDATE_CATEGORIES,
  CREATE_CYCLES,
  CREATE_GENERATE_SSPSA_TASK_TYPE,
  CREATE_SPACE_ASSIGNMENTS,
  CREATE_SPACE_PLANS,
  CREATE_SPACE_TYPES,
  CREATE_SPACE_GROUPS,
  IMPORT_JDA_CSV,
  READ_CHANGE_CONTROLS,
  READ_CYCLES,
  READ_MASTER_PSA,
  READ_NOMINATIONS,
  READ_PSA,
  UPDATE_PRODUCT_HFSS,
  READ_SPACES,
  READ_SPACE_ASSIGNMENTS,
  READ_SPACE_PLANS,
  READ_SPACE_PLANS_APPROVALS,
  READ_SPACE_PLANS_APPROVALS_ALL,
  READ_SPACE_TYPES,
  READ_SPACE_GROUPS,
  READ_USERS,
  UPDATE_ADMIN_CONFIG,
  CREATE_GROUPS,
  READ_GROUPS,
  READ_SHELVES_PRODUCTS,
} from './permissions'

const ROUTE_PERMISSIONS = {
  [CHANGE_CONTROLS_DETAIL_ROUTE]: [READ_CHANGE_CONTROLS],
  [CYCLES_CHANGE_CONTROLS_ROUTE]: [READ_CYCLES, READ_CHANGE_CONTROLS],
  [CYCLES_CREATE_ROUTE]: [CREATE_CYCLES],
  [CYCLES_DETAIL_ROUTE]: [READ_CYCLES],
  [CYCLES_SPACES_ROUTE]: [READ_CYCLES, READ_SPACE_ASSIGNMENTS],
  [CYCLES_MASTER_PSA_ROUTE]: [READ_CYCLES, READ_MASTER_PSA],
  [CYCLES_NOMINATIONS_ROUTE]: [READ_CYCLES],
  [CYCLES_ROUTE]: [READ_CYCLES],
  [CYCLES_STORE_PSA_ROUTE]: [READ_PSA],
  [CYCLES_STORE_PSA_SEARCH_ROUTE]: [READ_PSA],
  [CYCLES_SHIPPER_COMPLIANCE_ROUTE]: [READ_SPACE_ASSIGNMENTS],
  [CYCLES_SSPSA_COMMENTS_ROUTE]: [READ_CYCLES, READ_SPACE_ASSIGNMENTS],
  [IMPORTS_ROUTE]: [IMPORT_JDA_CSV],
  [ADMIN_CONFIG_ROUTE]: [UPDATE_ADMIN_CONFIG],
  [NOMINATIONS_DETAIL_ROUTE]: [READ_NOMINATIONS],
  [NOMINATIONS_ROUTE]: [READ_NOMINATIONS],
  [SSPSA_ROUTE]: [CREATE_GENERATE_SSPSA_TASK_TYPE],
  [SPACES_DETAIL_ROUTE]: [READ_SPACES],
  [SPACES_ROUTE]: [READ_SPACES],
  [SPACE_ASSIGNMENTS_CHANGE_CONTROL_ROUTE]: [READ_SPACE_ASSIGNMENTS],
  [SPACE_ASSIGNMENTS_POS_ROUTE]: [READ_SHELVES_PRODUCTS],
  [SPACE_ASSIGNMENTS_CLUSTER_ROUTE]: [READ_SPACE_ASSIGNMENTS],
  [SPACE_ASSIGNMENTS_CREATE_ROUTE]: [CREATE_SPACE_ASSIGNMENTS],
  [SPACE_ASSIGNMENTS_DETAIL_ROUTE]: [READ_SPACE_ASSIGNMENTS],
  [SPACE_PLANS_APPROVALS_ROUTE]: [READ_SPACE_PLANS_APPROVALS, READ_SPACE_PLANS_APPROVALS_ALL],
  [SPACE_PLANS_CREATE_ROUTE]: [CREATE_SPACE_PLANS],
  [SPACE_PLANS_CYCLES_ROUTE]: [READ_SPACE_PLANS],
  [SPACE_PLANS_DETAIL_ROUTE]: [READ_SPACE_PLANS],
  [SPACE_PLANS_PLANNER_ROUTE]: [READ_SPACE_ASSIGNMENTS],
  [SPACE_PLANS_ROUTE]: [READ_SPACE_PLANS],
  [SPACE_PLANS_TIMELINE_ROUTE]: [READ_SPACE_PLANS],
  [SPACE_TYPES_CREATE_ROUTE]: [CREATE_SPACE_TYPES],
  [SPACE_TYPES_DETAIL_ROUTE]: [READ_SPACE_TYPES],
  [SPACE_TYPES_ROUTE]: [READ_SPACE_TYPES],
  [SPACE_GROUPS_CREATE_ROUTE]: [CREATE_SPACE_GROUPS],
  [SPACE_GROUPS_DETAIL_ROUTE]: [READ_SPACE_GROUPS],
  [SPACE_GROUPS_ROUTE]: [READ_SPACE_GROUPS],
  [USERS_DETAIL_ROUTE]: [READ_USERS],
  [GROUPS_CREATE_ROUTE]: [CREATE_GROUPS],
  [GROUPS_ROUTE]: [READ_GROUPS],
  [GROUPS_DETAIL_ROUTE]: [READ_GROUPS],
  [CATEGORY_ROUTE]: [READ_CATEGORIES],
  [CATEGORY_CREATE_ROUTE]: [CREATE_CATEGORIES],
  [CATEGORY_DETAIL_ROUTE]: [UPDATE_CATEGORIES],
  [PRODUCT_ROUTE]: [UPDATE_PRODUCT_HFSS],
}

export default ROUTE_PERMISSIONS
