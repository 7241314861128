const validate = values => {
  const errors = {}
  const {
    product: { error: productError, sku },
  } = values

  if (!sku) {
    errors.product = { sku: 'SKU is required' }
  }

  if (sku && productError) {
    errors.product = { sku: productError }
  }
  return errors
}

export default validate
