import {
  CATEGORIES_TYPE,
  CHANGES_TYPE,
  CHANGE_CONTROLS_TYPE,
  CHANGE_SETS_TYPE,
  CLUSTERS_TYPE,
  CYCLES_TYPE,
  GENERATE_SSPSA_TASK_TYPE,
  NOMINATIONS_TEMPLATE_TYPE,
  NOMINATIONS_TYPE,
  SHELVES_PALLET_OR_STACK_TYPE,
  SHELVES_PRODUCTS_TYPE,
  SHELVES_TYPE,
  SHIPPER_COMPLIANCES_TYPE,
  SPACES_TYPE,
  SPACE_ASSIGNMENTS_TYPE,
  SPACE_INSTANCES_TYPE,
  SPACE_PLANS_APPROVALS_TYPE,
  SPACE_PLANS_TYPE,
  SPACE_TYPES_TYPE,
  SPACE_GROUPS_TYPE,
  USERS_TYPE,
  GROUPS_TYPE,
  POS_TYPE,
  SHELVES_PRODUCTS_POS,
  PRODUCT_HFSS,
} from 'src/constants/types'

export const UPDATE_PRODUCT_HFSS = `u-${PRODUCT_HFSS}`

export const READ_CATEGORIES = `r-categories-all`
export const CREATE_CATEGORIES = `c-${CATEGORIES_TYPE}`
export const UPDATE_CATEGORIES = `u-${CATEGORIES_TYPE}`

export const CREATE_CYCLES = `c-${CYCLES_TYPE}`
export const READ_CYCLES = `r-${CYCLES_TYPE}`
export const UPDATE_CYCLES = `u-${CYCLES_TYPE}`

export const CREATE_SPACE_PLANS = `c-${SPACE_PLANS_TYPE}`
export const READ_SPACE_PLANS = `r-${SPACE_PLANS_TYPE}`
export const UPDATE_SPACE_PLANS = `u-${SPACE_PLANS_TYPE}`

export const READ_USERS = `r-${USERS_TYPE}`
export const UPDATE_USERS = `u-${USERS_TYPE}`

export const UPDATE_ADMIN_CONFIG = `u-admin-config`

export const READ_NOMINATIONS = `r-${NOMINATIONS_TYPE}`
export const UPDATE_NOMINATIONS = `u-${NOMINATIONS_TYPE}`
export const READ_NOMINATIONS_ALL = `r-${NOMINATIONS_TYPE}-all`
export const UPDATE_NOMINATIONS_DOCUMENT = `u-${NOMINATIONS_TYPE}-document`

export const READ_NOMINATIONS_TEMPLATE = `r-${NOMINATIONS_TEMPLATE_TYPE}`
export const UPDATE_NOMINATIONS_TEMPLATE = `u-${NOMINATIONS_TEMPLATE_TYPE}`

export const READ_SPACE_PLANS_APPROVALS_ALL = `r-${SPACE_PLANS_APPROVALS_TYPE}-all`
export const READ_SPACE_PLANS_APPROVALS = `r-${SPACE_PLANS_APPROVALS_TYPE}`
export const UPDATE_SPACE_PLANS_APPROVALS = `u-${SPACE_PLANS_APPROVALS_TYPE}`

export const CREATE_SPACE_ASSIGNMENTS = `c-${SPACE_ASSIGNMENTS_TYPE}`
export const READ_SPACE_ASSIGNMENTS = `r-${SPACE_ASSIGNMENTS_TYPE}`
export const UPDATE_SPACE_ASSIGNMENTS = `u-${SPACE_ASSIGNMENTS_TYPE}`
export const UPDATE_SPACE_ASSIGNMENTS_START_DATE = `u-${SPACE_ASSIGNMENTS_TYPE}-start-date`
export const UPDATE_SPACE_ASSIGNMENTS_END_DATE = `u-${SPACE_ASSIGNMENTS_TYPE}-end-date`
export const UPDATE_SPACE_ASSIGNMENTS_TITLE = `u-${SPACE_ASSIGNMENTS_TYPE}-title`
export const UPDATE_SPACE_ASSIGNMENTS_COMMENT = `u-${SPACE_ASSIGNMENTS_TYPE}-comment`
export const UPDATE_SPACE_ASSIGNMENTS_PROPOSITION = `u-${SPACE_ASSIGNMENTS_TYPE}-proposition`
export const UPDATE_SPACE_ASSIGNMENTS_CATEGORIES = `u-${SPACE_ASSIGNMENTS_TYPE}-categories`
export const UPDATE_SPACE_ASSIGNMENTS_STORE_TITLE_COMMENT = `u-${SPACE_ASSIGNMENTS_TYPE}-store-title-comment`
export const DELETE_SPACE_ASSIGNMENTS = `d-${SPACE_ASSIGNMENTS_TYPE}`

export const UPDATE_SHIPPER_COMPLIANCES = `u-${SHIPPER_COMPLIANCES_TYPE}`

export const CREATE_SPACES = `c-${SPACES_TYPE}`
export const READ_SPACES = `r-${SPACES_TYPE}`
export const UPDATE_SPACES = `u-${SPACES_TYPE}`

export const CREATE_SPACE_TYPES = `c-${SPACE_TYPES_TYPE}`
export const READ_SPACE_TYPES = `r-${SPACE_TYPES_TYPE}`
export const UPDATE_SPACE_TYPES = `u-${SPACE_TYPES_TYPE}`

export const CREATE_SPACE_GROUPS = `c-${SPACE_GROUPS_TYPE}`
export const READ_SPACE_GROUPS = `r-${SPACE_GROUPS_TYPE}`
export const UPDATE_SPACE_GROUPS = `u-${SPACE_GROUPS_TYPE}`

export const IMPORT_JDA_CSV = 'import-jda-csv'
export const IMPORT_SKU_CSV = 'import-sku-csv'
export const IMPORT_PLINTHS_MIGRATION = 'import-plinths-migration'

export const READ_PSA = 'r-psa'
export const READ_MASTER_PSA = 'r-master-psa'

export const CREATE_SPACE_INSTANCES = `c-${SPACE_INSTANCES_TYPE}`
export const READ_SPACE_INSTANCES = `r-${SPACE_INSTANCES_TYPE}`
export const UPDATE_SPACE_INSTANCES = `u-${SPACE_INSTANCES_TYPE}`
export const UPDATE_SPACE_INSTANCES_COMMENT = `u-${SPACE_INSTANCES_TYPE}-comment`
export const UPDATE_SPACE_INSTANCES_OWN_CATEGORY = `u-${SPACE_INSTANCES_TYPE}-own-category`
export const UPDATE_SPACE_INSTANCES_POS_COMMENT = `u-${SPACE_INSTANCES_TYPE}-pos-comment`
export const UPDATE_SPACE_INSTANCES_SHELF_HEIGHTS = `u-${SPACE_INSTANCES_TYPE}-shelf-heights`
export const UPDATE_SPACE_INSTANCE_SHIPPER_DESCRIPTION = `u-${SPACE_INSTANCES_TYPE}-shipper-description`
export const DELETE_SPACE_INSTANCES = `d-${SPACE_INSTANCES_TYPE}`

export const ADD_SHELVES = `c-${SHELVES_TYPE}`
export const DELETE_SHELVES = `d-${SHELVES_TYPE}`
export const UPDATE_SHELVES_CATEGORIES = `u-${SHELVES_TYPE}-categories`
export const UPDATE_SHELVES_ORDER = `u-${SHELVES_TYPE}-order`

export const UPDATE_SHELVES_PALLET_OR_STACK_TYPE_ALL = `u-${SHELVES_PALLET_OR_STACK_TYPE}-all`
export const UPDATE_SHELVES_PALLET_OR_STACK_TYPE_OWN_CATEGORY = `u-${SHELVES_PALLET_OR_STACK_TYPE}-own-category`

export const READ_SHELVES_PRODUCTS = `r-${SHELVES_PRODUCTS_TYPE}`
export const CREATE_SHELVES_PRODUCTS = `c-${SHELVES_PRODUCTS_TYPE}`
export const DELETE_SHELVES_PRODUCTS = `d-${SHELVES_PRODUCTS_TYPE}`
export const UPDATE_SHELVES_PRODUCTS = `u-${SHELVES_PRODUCTS_TYPE}`

export const CREATE_SHELVES_PRODUCTS_WHEN_LOCKED = `c-${SHELVES_PRODUCTS_TYPE}-locked`
export const DELETE_SHELVES_PRODUCTS_WHEN_LOCKED = `d-${SHELVES_PRODUCTS_TYPE}-locked`
export const UPDATE_SHELVES_PRODUCTS_WHEN_LOCKED = `u-${SHELVES_PRODUCTS_TYPE}-locked`

export const CREATE_SHELVES_PRODUCTS_ALL = `c-${SHELVES_PRODUCTS_TYPE}-all`
export const UPDATE_SHELVES_PRODUCTS_ALL = `u-${SHELVES_PRODUCTS_TYPE}-all`
export const UPDATE_SHELVES_PRODUCTS_OWN = `u-${SHELVES_PRODUCTS_TYPE}-own`
export const UPDATE_SHELVES_PRODUCTS_OWN_CATEGORY = `u-${SHELVES_PRODUCTS_TYPE}-own-category`
export const DELETE_SHELVES_PRODUCTS_ALL = `d-${SHELVES_PRODUCTS_TYPE}-all`
export const DELETE_SHELVES_PRODUCTS_OWN = `d-${SHELVES_PRODUCTS_TYPE}-own`
export const DELETE_SHELVES_PRODUCTS_OWN_CATEGORY = `d-${SHELVES_PRODUCTS_TYPE}-own-category`

export const UPDATE_SHELVES_PRODUCTS_CATEGORY = `u-${SHELVES_PRODUCTS_TYPE}-category`
export const UPDATE_SHELVES_PRODUCTS_PROMO_MECHANIC = `u-${SHELVES_PRODUCTS_TYPE}-promo-mechanic`
export const UPDATE_SHELVES_PRODUCTS_PACK_SIZE = `u-${SHELVES_PRODUCTS_TYPE}-pack-size`
export const UPDATE_SHELVES_PRODUCTS_SHELF_FILL = `u-${SHELVES_PRODUCTS_TYPE}-shelf-fill`
export const UPDATE_SHELVES_PRODUCTS_COMMENT = `u-${SHELVES_PRODUCTS_TYPE}-comment`
export const UPDATE_SHELVES_PRODUCTS_STORE_COMMENT = `u-${SHELVES_PRODUCTS_TYPE}-store-comment`
export const UPDATE_SHELVES_PRODUCTS_CLUSTERS = `u-${SHELVES_PRODUCTS_TYPE}-clusters`
export const UPDATE_SHELVES_PRODUCTS_PERCENT_UPLIFT_PLINTH = `u-${SHELVES_PRODUCTS_TYPE}-percent-uplift-plinth`
export const UPDATE_SHELVES_PRODUCTS_PERCENT_UPLIFT_AISLE = `u-${SHELVES_PRODUCTS_TYPE}-percent-uplift-aisle`
export const UPDATE_SHELVES_PRODUCTS_WIGIG = `u-${SHELVES_PRODUCTS_TYPE}-wigig`
export const UPDATE_SHELVES_PRODUCTS_PRICE_POINT = `u-${SHELVES_PRODUCTS_TYPE}-price-point`
export const UPDATE_SHELVES_PRODUCTS_PROMO_CASE_SIZE = `u-${SHELVES_PRODUCTS_TYPE}-promo-case-size`
export const UPDATE_SHELVES_PRODUCTS_PROMOTIONAL = `u-${SHELVES_PRODUCTS_TYPE}-promotional`
export const UPDATE_SHELVES_PRODUCTS_NF_PROMOTIONAL = `u-${SHELVES_PRODUCTS_TYPE}-nf-promotional`
export const UPDATE_SHELVES_PRODUCTS_NF_STOCK = `u-${SHELVES_PRODUCTS_TYPE}-nf-stock`
export const UPDATE_SHELVES_PRODUCTS_NF_FINANCIALS = `u-${SHELVES_PRODUCTS_TYPE}-nf-financials`
export const UPDATE_SHELVES_PRODUCTS_PRODUCE_TABLE_POSITIONS_ALL = `u-${SHELVES_PRODUCTS_TYPE}-produce-table-positions-all`
export const UPDATE_SHELVES_PRODUCTS_PRODUCE_TABLE_POSITIONS_OWN_CATEGORY = `u-${SHELVES_PRODUCTS_TYPE}-produce-table-positions-own-category`

export const READ_CHANGES = `r-${CHANGES_TYPE}`
export const READ_CHANGE_SETS = `r-${CHANGE_SETS_TYPE}`

export const READ_CLUSTERS = `r-${CLUSTERS_TYPE}`

export const CREATE_GENERATE_SSPSA_TASK_TYPE = `c-${GENERATE_SSPSA_TASK_TYPE}`
export const READ_GENERATE_SSPSA_TASK_TYPE = `r-${GENERATE_SSPSA_TASK_TYPE}`

export const CHANGE_CONTROLS_4WK_ONLY = `x-${CHANGE_CONTROLS_TYPE}-4wk-only`
export const CREATE_CHANGE_CONTROLS = `c-${CHANGE_CONTROLS_TYPE}`
export const READ_CHANGE_CONTROLS = `r-${CHANGE_CONTROLS_TYPE}`
export const UPDATE_CHANGE_CONTROLS = `u-${CHANGE_CONTROLS_TYPE}`
export const DELETE_CHANGE_CONTROLS_ALL = `d-${CHANGE_CONTROLS_TYPE}-all`
export const DELETE_CHANGE_CONTROLS_OWN = `d-${CHANGE_CONTROLS_TYPE}-own`
export const EXPORT_CHANGE_CONTROLS = `export-${CHANGE_CONTROLS_TYPE}`

export const UPDATE_CHANGE_CONTROLS_APPROVE = `u-${CHANGE_CONTROLS_TYPE}-approve`
export const UPDATE_CHANGE_CONTROLS_APPROVE_ALL = `u-${CHANGE_CONTROLS_TYPE}-approve-all`
export const UPDATE_CHANGE_CONTROLS_APPROVE_OWN_CATEGORY = `u-${CHANGE_CONTROLS_TYPE}-approve-own-category`
export const UPDATE_CHANGE_CONTROLS_ACCEPT = `u-${CHANGE_CONTROLS_TYPE}-accept`
export const UPDATE_CHANGE_CONTROLS_ACCEPT_OWN_CATEGORY = `u-${CHANGE_CONTROLS_TYPE}-accept-own-category`
export const UPDATE_CHANGE_CONTROLS_AMEND = `u-${CHANGE_CONTROLS_TYPE}-request-amend`
export const UPDATE_CHANGE_CONTROLS_AMEND_OWN = `u-${CHANGE_CONTROLS_TYPE}-request-amend-own`
export const UPDATE_CHANGE_CONTROLS_AMEND_OWN_CATEGORY = `u-${CHANGE_CONTROLS_TYPE}-request-amend-own-category`
export const UPDATE_CHANGE_CONTROLS_AMEND_ALL = `u-${CHANGE_CONTROLS_TYPE}-request-amend-all`
export const UPDATE_CHANGE_CONTROLS_REJECT = `u-${CHANGE_CONTROLS_TYPE}-reject`
export const UPDATE_CHANGE_CONTROLS_REJECT_OWN_CATEGORY = `u-${CHANGE_CONTROLS_TYPE}-reject-own-category`
export const UPDATE_CHANGE_CONTROLS_WITHDRAW = `u-${CHANGE_CONTROLS_TYPE}-withdraw`
export const UPDATE_CHANGE_CONTROLS_WITHDRAW_OWN = `u-${CHANGE_CONTROLS_TYPE}-withdraw-own`
export const CREATE_GROUPS = `c-${GROUPS_TYPE}`
export const READ_GROUPS = `r-${GROUPS_TYPE}`
export const UPDATE_GROUPS = `u-${GROUPS_TYPE}`
export const READ_POS = `r-${POS_TYPE}`
export const UPDATE_SHELVES_PRODUCTS_POS_ALL = `u-${SHELVES_PRODUCTS_POS}-all`
export const UPDATE_SHELVES_PRODUCTS_POS_OWN_CATEGORY = `u-${SHELVES_PRODUCTS_POS}-own-category`
