export const AVAILABLE_SSPSAS_TYPE = 'available-sspsas'
export const CATEGORIES_TYPE = 'categories'
export const CHANGES_TYPE = 'changes'
export const CHANGE_CONTROL_REASONS_TYPE = 'change-control-reasons'
export const CHANGE_CONTROLS_TYPE = 'change-controls'
export const CHANGE_SETS_TYPE = 'change-sets'
export const CLUSTERS_TYPE = 'clusters'
export const CYCLES_TYPE = 'cycles'
export const DEPT_BUYERS_TYPE = 'dept-buyers'
export const DOWNLOADS_TYPE = 'downloads'
export const EXPORTS_TYPE = 'exports'
export const FEATURE_TOGGLES_TYPE = 'feature-toggles'
export const GENERATE_SSPSA_TASK_TYPE = 'generate-sspsa-tasks'
export const HFSS_COUNTRY_TYPE = 'hfsscountry'
export const IMPORTS_RESPONSE_TYPE = 'imports-response'
export const MASTER_PSA_ENTRIES_TYPE = 'master-psa-entries'
export const MESSAGES_TYPE = 'messages'
export const NOMINATIONS_TEMPLATE_TYPE = 'nominations-template'
export const NOMINATIONS_TYPE = 'sharepoint-nominations'
export const PRODUCTS_TYPE = 'products'
export const PROMO_MECHANICS_TYPE = 'promo-mechanics'
export const PSA_ENTRIES_TYPE = 'psa-entries'
export const PSA_GROUPS_TYPE = 'psa-groups'
export const ROLES_TYPE = 'roles'
export const SHELVES_PALLET_OR_STACK_TYPE = 'shelves-pallet-or-stack-type'
export const SHELVES_PRODUCTS_TYPE = 'shelves-products'
export const SHELF_ARTIFACTS_TYPE = 'shelf-artifacts'
export const SHELF_PRODUCT_LAYOUTS_TYPE = 'shelf-product-layouts'
export const SHELVES_TYPE = 'shelves'
export const SHIPPER_COMPLIANCES_TYPE = 'shipper-compliances'
export const SPACES_TYPE = 'spaces'
export const SPACE_ASSIGNMENTS_TYPE = 'space-assignments'
export const SPACE_INSTANCES_TYPE = 'space-instances'
export const SPACE_PLANS_APPROVALS_TYPE = 'space-plans-approvals'
export const SPACE_PLANS_TEMPLATE_TYPE = 'space-plans-template'
export const SPACE_PLANS_TYPE = 'space-plans'
export const SPACE_TYPES_TYPE = 'space-types'
export const SPACE_TYPE_LAYOUT_TYPES_TYPE = 'space-type-layout-types'
export const SPACE_GROUPS_TYPE = 'space-groups'
export const STORES_TYPE = 'stores'
export const STORE_SPACE_CLUSTER_SETS_TYPE = 'store-space-cluster-sets'
export const USERS_TYPE = 'users'
export const USER_REQUESTS_TYPE = 'user-requests'
export const GROUPS_TYPE = 'groups'
export const POS_TYPE = 'point-of-sales'
export const SHELVES_PRODUCTS_POS = 'shelves-products-pos'
export const PRODUCT_HFSS = 'product-hfss'
