export const ROOT_ROUTE = '/'

export const USERS_ROUTE = '/users'
export const USERS_DETAIL_ROUTE = '/users/:id'

export const SPACE_PLANS_ROUTE = '/space-plans'
export const SPACE_PLANS_CREATE_ROUTE = `${SPACE_PLANS_ROUTE}/create`
export const SPACE_PLANS_DETAIL_ROUTE = `${SPACE_PLANS_ROUTE}/:id`
export const SPACE_PLANS_PLANNER_ROUTE = `${SPACE_PLANS_ROUTE}/:id/planner`
export const SPACE_PLANS_APPROVALS_ROUTE = `${SPACE_PLANS_ROUTE}/:id/approvals`
export const SPACE_PLANS_CYCLES_ROUTE = `${SPACE_PLANS_ROUTE}/:id/cycles`
export const SPACE_PLANS_TIMELINE_ROUTE = `${SPACE_PLANS_ROUTE}/:id/timeline`

export const CYCLES_ROUTE = '/cycles/:businessUnit'
export const CYCLES_CREATE_ROUTE = `${CYCLES_ROUTE}/create`
export const CYCLES_DETAIL_ROUTE = `${CYCLES_ROUTE}/:id/details`
export const CYCLES_SPACES_ROUTE = `${CYCLES_ROUTE}/:id`
export const CYCLES_NOMINATIONS_ROUTE = `${CYCLES_ROUTE}/:id/nominations`
export const CYCLES_MASTER_PSA_ROUTE = `${CYCLES_ROUTE}/:id/master-psa`
export const CYCLES_STORE_PSA_SEARCH_ROUTE = `${CYCLES_ROUTE}/:id/store-psa`
export const CYCLES_STORE_PSA_ROUTE = `${CYCLES_ROUTE}/:id/store-psa/:storeId`
export const CYCLES_CHANGE_CONTROLS_ROUTE = `${CYCLES_ROUTE}/:id/change-controls`
export const CYCLES_SHIPPER_COMPLIANCE_ROUTE = `${CYCLES_ROUTE}/:id/shipper-compliance`
export const CYCLES_SSPSA_COMMENTS_ROUTE = `${CYCLES_ROUTE}/:id/sspsa-comments`

export const NOMINATIONS_ROUTE = '/nominations'
export const NOMINATIONS_DETAIL_ROUTE = '/nominations/:id'

export const ADMIN_CONFIG_ROUTE = '/admin-config'

export const SPACE_ASSIGNMENTS_DETAIL_ROUTE = '/space-assignments/:id'
export const SPACE_ASSIGNMENTS_CHANGE_CONTROL_ROUTE = `${SPACE_ASSIGNMENTS_DETAIL_ROUTE}/change-controls/:changeControlId`
export const SPACE_ASSIGNMENTS_CLUSTER_ROUTE = `${SPACE_ASSIGNMENTS_DETAIL_ROUTE}/clusters`
export const SPACE_ASSIGNMENTS_POS_ROUTE = `${SPACE_ASSIGNMENTS_DETAIL_ROUTE}/shelf/:shelfId/pos`
export const SPACE_ASSIGNMENTS_CREATE_ROUTE = '/space-assignments/create'

export const SPACES_ROUTE = '/spaces'
export const SPACES_DETAIL_ROUTE = '/spaces/:id'

export const SPACE_TYPES_ROUTE = '/spaces/types'
export const SPACE_TYPES_CREATE_ROUTE = '/spaces/types/create'
export const SPACE_TYPES_DETAIL_ROUTE = '/spaces/types/:id'

export const SPACE_GROUPS_ROUTE = '/spaces/groups'
export const SPACE_GROUPS_CREATE_ROUTE = '/spaces/groups/create'
export const SPACE_GROUPS_DETAIL_ROUTE = '/spaces/groups/:id'

export const IMPORTS_ROUTE = '/imports'

export const UNASSIGNED_ROUTE = '/unassigned'
export const NO_ACCESS_ROUTE = '/no-access'

export const CHANGE_CONTROLS_DETAIL_ROUTE = `/change-controls/:id`

export const EXPORTS_ROUTE = '/exports'

export const SSPSA_ROUTE = `/sspsa`

export const RANGE_AND_SPACE_ROUTE = '/range-and-space/:storeId'

export const GROUPS_ROUTE = '/groups'
export const GROUPS_CREATE_ROUTE = `${GROUPS_ROUTE}/create`
export const GROUPS_DETAIL_ROUTE = `${GROUPS_ROUTE}/:id`

export const CATEGORY_ROUTE = '/categories'
export const CATEGORY_CREATE_ROUTE = `${CATEGORY_ROUTE}/create`
export const CATEGORY_DETAIL_ROUTE = `${CATEGORY_ROUTE}/:id`

export const PRODUCT_ROUTE = '/product'
