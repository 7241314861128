import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  FlagWrapper,
  FlagBody,
  FlagComponent,
  FilledButton,
  TextInputField,
  ProgressSpinner,
} from '@jsluna/react'

import selectPath from 'src/utils/selectPath'

import { actionGetProductForSku } from './store'

export class InlineProductSearch extends Component {
  constructor(props) {
    super(props)

    this.state = {
      searching: false,
    }

    this.search = this.search.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      event.target.blur()
      this.search(event)
    }
  }

  async search(event) {
    event.preventDefault()

    const {
      getProductForSku,
      field: { value },
      form: { setFieldValue },
    } = this.props

    this.setState({ searching: true })

    const { data: product = null, error } = await getProductForSku(value)

    if (product && Object.entries(product).length > 0) {
      setFieldValue('product', { ...product, isHfss: product.hfss === 'YES' })
    } else {
      setFieldValue('product', { id: '', description: '', sku: value, error: error?.data.detail })
    }

    this.setState({ searching: false })
  }

  render() {
    const { field, form, label } = this.props
    const { onBlur, onChange, name, value } = field
    const { errors, setFieldValue, touched } = form
    const error = selectPath(touched, name) && selectPath(errors, name)
    const { searching } = this.state

    const wrap = callback => event => {
      setFieldValue('product', { id: '', description: '', sku: value }, false)
      callback(event)
    }

    const wrappedOnChange = wrap(onChange)

    return (
      <FlagWrapper className="ln-u-push-bottom">
        <FlagBody alignment="bottom">
          <TextInputField
            validationFirst={false}
            {...field}
            className="ln-u-flush-bottom ln-u-soft-right"
            label={label}
            error={error}
            value={field.value}
            onBlur={onBlur}
            onChange={wrappedOnChange}
            onKeyPress={this.handleKeyPress}
            autoFocus
          />
        </FlagBody>
        <FlagComponent alignment={error ? undefined : 'bottom'} data-control="search-sku">
          <FilledButton color="dark" className="c-search-button" onClick={this.search} fullWidth>
            {searching ? (
              <ProgressSpinner className="u-flush-right" color="light" size="small" />
            ) : (
              'Search'
            )}
          </FilledButton>
        </FlagComponent>
      </FlagWrapper>
    )
  }
}

InlineProductSearch.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
    setFieldValue: PropTypes.func,
  }).isRequired,
  label: PropTypes.string.isRequired,
  getProductForSku: PropTypes.func.isRequired,
}

const mapDispatchToProps = {
  getProductForSku: actionGetProductForSku,
}

export default connect(null, mapDispatchToProps)(InlineProductSearch)
