import PropTypes from 'prop-types'
import React from 'react'
import { GridWrapper, GridItem, Section } from '@jsluna/react'
import { Field, Form, withFormik } from 'formik'
import { Card } from '@jsluna/card'
import { LabelledText, Checkbox } from 'src/components/FormikComponents'
import { Heading2 } from '@jsluna/typography'
import { FormGroup } from '@jsluna/form'

import { Controls } from 'src/components/Form'
import ProductSearch from 'src/modules/InlineProductSearch'
import validate from './validate'

const ProductHfssUpdateForm = ({ handleSubmit: onSubmit, values: { product } }) => {
  return (
    <Form onSubmit={onSubmit}>
      <Heading2>
        <span data-control="product-hfss-title">Update Product HFSS</span>
      </Heading2>
      <Section>
        <Card>
          <GridWrapper className="ln-u-push-bottom">
            <GridItem size="1/2">
              <Field
                label="SKU"
                component={ProductSearch}
                name="product.sku"
                validationFirst={false}
              />
            </GridItem>
          </GridWrapper>

          {product?.id && (
            <>
              <FormGroup name="product-details-fields">
                <GridWrapper matrix>
                  <GridItem size="1/2@sm">
                    <Field label="SKU" name="product.sku" component={LabelledText} />
                  </GridItem>
                  <GridItem size="1/2@sm">
                    <Field
                      label="Description"
                      name="product.description"
                      component={LabelledText}
                    />
                  </GridItem>
                  <GridItem size="1/2@sm">
                    <Field label="Dept/Comm" name="product.deptComm" component={LabelledText} />
                  </GridItem>
                  <GridItem size="1/2@sm">
                    <Field label="Status" name="product.skuStatus" component={LabelledText} />
                  </GridItem>

                  <GridItem size="1/1@sm">
                    <div className="ln-c-form-group">
                      <Field component={Checkbox} label="HFSS Status" name="product.isHfss" />
                    </div>
                  </GridItem>
                </GridWrapper>
              </FormGroup>
              <Controls submitText="Update HFSS Status" />
            </>
          )}
        </Card>
      </Section>
    </Form>
  )
}

ProductHfssUpdateForm.propTypes = {
  values: PropTypes.shape({
    product: PropTypes.shape({
      id: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      sku: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
}

const mapPropsToValues = () => ({
  product: {
    description: '',
    sku: '',
    id: '',
  },
})
export const handleSubmit = async (values, { props }) => {
  const { sku, isHfss } = values.product
  await props.updateProductHfss({ sku, status: isHfss })
}

const wrappedForm = withFormik({ mapPropsToValues, handleSubmit, validate })(ProductHfssUpdateForm)

export default wrappedForm
