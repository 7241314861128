import { CYCLES_ROUTE } from 'src/constants/routes'

export default [
  { title: 'Space Plans', path: '/space-plans' },
  {
    title: 'Cycles',
    items: [
      { title: 'Food', path: '/cycles/food', pathForPermissions: CYCLES_ROUTE },
      { title: 'Non-food', path: '/cycles/non-food', pathForPermissions: CYCLES_ROUTE },
    ],
  },
  { title: 'Nominations', path: '/nominations' },
  { title: 'Spaces', path: '/spaces' },
  { title: 'Users', path: '/users' },
  { title: 'Imports', path: '/imports' },
  { title: 'Exports', path: '/exports' },
  { title: 'SSPSA', path: '/sspsa' },
  { title: 'Groups', path: '/groups' },
  { title: 'Admin Config', path: '/admin-config' },
  { title: 'Categories', path: '/categories' },
  { title: 'Product', path: '/product' },
]
