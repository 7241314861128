import { createAsyncThunk } from '@reduxjs/toolkit'
import { handleCreateAsyncThunkResult, buildThunkPrefix } from 'src/utils/createAsyncThunkHandler'

import PatchProductHfss from 'src/api/Product/PatchProductHfss'
import { createNotification } from 'src/store/notifications/actions'

const NAMESPACE = 'ProductHfss'

const actionUpdateProductHfss = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'actionUpdateProductHfss'),
  async ({ sku, status }, { getState, dispatch, rejectWithValue }) => {
    const store = getState()
    const patchProductHfssInstance = new PatchProductHfss(store, {
      params: { sku, status },
    })

    const response = await handleCreateAsyncThunkResult(
      patchProductHfssInstance,
      dispatch,
      rejectWithValue
    )
    if (response?.successResult) dispatch(createNotification('HFSS updated successfully', false))
    return response
  }
)

// eslint-disable-next-line import/prefer-default-export
export { actionUpdateProductHfss }
